var menuscroll;
(function ($, window) {
	'use strict';

	var $doc = $(document),
		win = $(window),
		body = $('body');

	var SITE = SITE || {};

	SITE = {
		init: function () {
			var self = this,
				obj;

			function initFunctions() {
				for (obj in self) {
					if (self.hasOwnProperty(obj)) {
						var _method = self[obj];
						if (_method.selector !== undefined && _method.init !== undefined) {
							if ($(_method.selector).length > 0) {
								_method.init();
							}
						}
					}
				}
			}

		
			initFunctions();
		},
		responsiveNav: {
			selector: '#wrapper',
			init: function () {
				var base = this,
					container = $(base.selector),
					toggle = $('.mobile-toggle', '.header'),
					cc = $('.click-capture', '#content-container'),
					target = $('#mobile-menu'),
					parents = target.find('.thb-mobile-menu>li>a'),
					span = target.find('.thb-mobile-menu li>span'),
					quick_search = $('.quick_search'),
					header_social = $('.social_header');

				toggle.on('click', function () {
					container.toggleClass('open-menu');
					return false;
				});

				cc.add(target.find('.close')).on('click', function () {
					container.removeClass('open-menu');
					parents.find('.sub-menu').hide();

					return false;
				});

				span.on('click', function () {
					var that = $(this),
						link = that.prev('a'),
						parents = target.find('a');

					if (!that.parents('.sub-menu').length) {
						parents.filter('.active').not(link).removeClass('active').parent('li').find('.sub-menu').eq(0).slideUp();
					}

					if (link.hasClass('active')) {
						link.removeClass('active').parent('li:eq(0)').find('.sub-menu').eq(0).slideUp('200');
					} else {
						link.addClass('active').parent('li:eq(0)').find('.sub-menu').eq(0).slideDown('200');
					}

					return false;
				});

				quick_search.on('click', function (e) {
					if (e.target.classList.contains('quick_search') || e.target.classList.contains('search_icon')) {
						quick_search.toggleClass('active');
						e.stopPropagation();
					}
					return false;
				});
				header_social.on('click', 'i.social_toggle', function () {
					header_social.toggleClass('active');
					return false;
				});
			}
		},
		
		fixedHeader: {
			selector: '.header.fixed',
			init: function () {
				var base = this,
					container = $(base.selector),
					single = body.hasClass('single-post');

				win.scroll(function () {
					base.scroll(container, single);
				}).trigger('scroll');

			},
			scroll: function (container, single) {
				var animationOffset = 400,
					wOffset = win.scrollTop(),
					stick = 'header--slide',
					unstick = 'header--unslide';

				if (wOffset > animationOffset) {
					if (container.hasClass(unstick)) {
						container.removeClass(unstick);
					}
					if (!container.hasClass(stick)) {
						setTimeout(function () {
							container.addClass(stick);
						}, 10);
					}
				} else if ((wOffset < animationOffset && (wOffset > 0))) {
					if (container.hasClass(stick)) {
						container.removeClass(stick);
						container.addClass(unstick);
					}
				} else {
					container.removeClass(stick);
					container.removeClass(unstick);
				}
			}

		},
		fullHeightContent: {
			selector: '.full-height-content',
			init: function () {
				var base = this,
					container = $(base.selector);

				base.control(container);

				win.resize(_.debounce(function () {
					base.control(container);
				}, 50));

			},
			control: function (container) {
				var h = $('.header'),
					a = $('#wpadminbar'),
					ah = (a ? a.outerHeight() : 0);

				container.each(function () {
					var _this = $(this),
						height = win.height() - h.outerHeight() - ah;

					_this.css('min-height', height);

				});
			}
		}

	};

	$doc.ready(function () {
		
		SITE.init();
		
	});

})(jQuery, this);
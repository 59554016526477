
function notificationAd() {
    $(window).scroll(function() {
        var halfHeightOfWin = $(document).height() / 2;
        var $notiAdModal = $('.notification-ad');
        var scrollPos = $(this).scrollTop();
        console.log('[notification-ad] scrollPos :' + scrollPos );
        if (scrollPos  > halfHeightOfWin) {

            $notiAdModal
                .removeClass('fadeOutRight')
                .addClass('fadeInRight')
                .show();


        }else{
            $notiAdModal.hide();
        }
    });
}

$(document).ready(function() {
    notificationAd();
});